var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        size: "xl",
        title: _vm.modalTitle,
        "button-size": "sm",
        "ok-only": "",
        "no-close-on-backdrop": "",
        "no-close-on-esc": ""
      },
      model: {
        value: _vm.showModal,
        callback: function($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal"
      }
    },
    [
      _c(
        "b-overlay",
        { attrs: { show: _vm.loading } },
        [
          _c("b-table", {
            attrs: {
              fields: _vm.headers,
              items: _vm.results,
              small: "",
              striped: "",
              bordered: ""
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }