var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "b-row",
        { staticClass: "mb-3" },
        [
          _c("b-col", { staticClass: "text-left" }, [
            _c("h4", { staticClass: "text--white" }, [
              _vm._v("Employee Activity")
            ])
          ])
        ],
        1
      ),
      _c(
        "b-overlay",
        { attrs: { show: _vm.loading } },
        [
          _c(
            "b-card",
            { staticClass: "card-border text--black" },
            [
              _c(
                "b-form",
                {
                  attrs: { inline: "" },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.search.apply(null, arguments)
                    }
                  }
                },
                [
                  _c("label", { staticClass: "mr-1" }, [_vm._v("Employee:")]),
                  _c("b-form-select", {
                    staticClass: "mr-3",
                    attrs: { options: _vm.formdata.employees },
                    scopedSlots: _vm._u([
                      {
                        key: "first",
                        fn: function() {
                          return [
                            _c(
                              "b-form-select-option",
                              { attrs: { value: null } },
                              [_vm._v("Select User")]
                            )
                          ]
                        },
                        proxy: true
                      }
                    ]),
                    model: {
                      value: _vm.form.employee_id,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "employee_id", $$v)
                      },
                      expression: "form.employee_id"
                    }
                  }),
                  _c("label", { staticClass: "mr-1" }, [_vm._v("Type:")]),
                  _c("b-form-select", {
                    staticClass: "mr-3",
                    attrs: { options: _vm.formdata.tasktypes },
                    scopedSlots: _vm._u([
                      {
                        key: "first",
                        fn: function() {
                          return [
                            _c(
                              "b-form-select-option",
                              { attrs: { value: null } },
                              [_vm._v("Task Type")]
                            )
                          ]
                        },
                        proxy: true
                      }
                    ]),
                    model: {
                      value: _vm.form.tasktype_id,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "tasktype_id", $$v)
                      },
                      expression: "form.tasktype_id"
                    }
                  }),
                  _c("label", { staticClass: "mr-1" }, [_vm._v("Year:")]),
                  _c("b-form-select", {
                    staticClass: "mr-3",
                    attrs: { options: _vm.formdata.years },
                    scopedSlots: _vm._u([
                      {
                        key: "first",
                        fn: function() {
                          return [
                            _c(
                              "b-form-select-option",
                              { attrs: { value: null } },
                              [_vm._v("Year")]
                            )
                          ]
                        },
                        proxy: true
                      }
                    ]),
                    model: {
                      value: _vm.form.year,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "year", $$v)
                      },
                      expression: "form.year"
                    }
                  }),
                  _c("label", { staticClass: "mr-1" }, [_vm._v("Month:")]),
                  _c("b-form-select", {
                    staticClass: "mr-3",
                    attrs: { options: _vm.formdata.months },
                    scopedSlots: _vm._u([
                      {
                        key: "first",
                        fn: function() {
                          return [
                            _c(
                              "b-form-select-option",
                              { attrs: { value: null } },
                              [_vm._v("Month")]
                            )
                          ]
                        },
                        proxy: true
                      }
                    ]),
                    model: {
                      value: _vm.form.month,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "month", $$v)
                      },
                      expression: "form.month"
                    }
                  }),
                  _c(
                    "b-button",
                    { attrs: { variant: "success", type: "submit" } },
                    [_vm._v("Search")]
                  )
                ],
                1
              ),
              _vm.results
                ? _c("b-table", {
                    staticClass: "my-5 small",
                    attrs: {
                      id: "activityTable",
                      fields: _vm.headers,
                      items: _vm.results,
                      "sort-by": _vm.sort.column,
                      "sort-desc": _vm.sort.reverse,
                      bordered: "",
                      "sticky-header": "600px",
                      "show-empty": "",
                      "primary-key": "employee_id",
                      "tbody-tr-class": "align-middle",
                      "details-td-class": "w-100 p-3",
                      "tfoot-tr-class": "align-middle",
                      striped: ""
                    },
                    on: {
                      "update:sortBy": function($event) {
                        return _vm.$set(_vm.sort, "column", $event)
                      },
                      "update:sort-by": function($event) {
                        return _vm.$set(_vm.sort, "column", $event)
                      },
                      "update:sortDesc": function($event) {
                        return _vm.$set(_vm.sort, "reverse", $event)
                      },
                      "update:sort-desc": function($event) {
                        return _vm.$set(_vm.sort, "reverse", $event)
                      },
                      "sort-changed": function(ctx) {
                        return _vm.$emit("sort-changed", ctx)
                      }
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "cell(employee_id)",
                          fn: function(data) {
                            return [
                              _c("b-icon", {
                                staticClass: "pointer",
                                attrs: {
                                  icon: data.detailsShowing
                                    ? "chevron-up"
                                    : "chevron-down"
                                },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return function() {
                                      return _vm.$set(
                                        data.item,
                                        "_showDetails",
                                        !data.item._showDetails
                                      )
                                    }.apply(null, arguments)
                                  }
                                }
                              })
                            ]
                          }
                        },
                        {
                          key: "row-details",
                          fn: function(row) {
                            return [
                              _c(
                                "b-table-simple",
                                [
                                  _c(
                                    "b-thead",
                                    [
                                      _c(
                                        "b-tr",
                                        [
                                          _c(
                                            "b-th",
                                            { staticClass: "text-left" },
                                            [_vm._v("Type")]
                                          ),
                                          _vm._l(
                                            _vm.getDetailTaskStatuses(
                                              row.item.types
                                            ),
                                            function(s, sidx) {
                                              return _c("b-th", { key: s }, [
                                                _vm._v(_vm._s(sidx))
                                              ])
                                            }
                                          )
                                        ],
                                        2
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-tbody",
                                    _vm._l(
                                      _vm.getDetailTaskTypes(row.item.types),
                                      function(t) {
                                        return _c(
                                          "b-tr",
                                          { key: t },
                                          [
                                            _c(
                                              "b-th",
                                              { staticClass: "text-left" },
                                              [_vm._v(_vm._s(t))]
                                            ),
                                            _vm._l(
                                              _vm.getDetailTaskStatuses(
                                                row.item.types
                                              ),
                                              function(s, sidx) {
                                                return _c("b-td", { key: s }, [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        parseInt(
                                                          row.item.types[t][
                                                            sidx
                                                          ]
                                                        ) || 0
                                                      ) +
                                                      " "
                                                  )
                                                ])
                                              }
                                            )
                                          ],
                                          2
                                        )
                                      }
                                    ),
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-button",
                                {
                                  attrs: { type: "button", variant: "info" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.viewDetail(row.item)
                                    }
                                  }
                                },
                                [_vm._v("View Detailed Activity")]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      820244071
                    )
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c("ActivityDetail", {
        attrs: {
          show: _vm.showActivityDetail,
          data: _vm.selectedEmployee,
          filters: _vm.form
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }